// Main nav js file

(function() {

	var $globalNav = $('#global-nav'); // Only the part we want to show/hide
	var $globalNavToggle = $('#global-nav-toggle');
	// $globalNav.addClass('hidden');
	$globalNavToggle.on('click', function() {
		$globalNav.slideToggle(150);
	});

	var $subNavHeader = $('#global-nav .nav-group-header');
	var $subNavGroup = $('#global-nav .nav-group');
	// $subNavGroup.addClass('hidden');
	$subNavHeader.on('click', function() {
		$(this).next('.sub-nav').slideToggle(150); // Show hide this sub nav
		$(this).parent().addClass('open'); // Register that this sub nav is open by adding a class to the nav-group
		$(this).parent().siblings('.open').find('.sub-nav').slideUp(150); // Closes any open .sub-nav
		navOpen =  true;
	});
	$('#main').on('click', function() {
		$('#global-nav .nav-group .sub-nav').slideUp(150);
	});

	// Set current class on nav current nav item parents
	$('#global-nav a.current').closest('.nav-group').find('.nav-group-header').addClass('current');
	currentSlug = window.location.pathname.split('/');
	$('#global-nav a[href*="' + currentSlug[1] + '"]').addClass('current');

	// Set layout of featured artists in nav
	function columnsBreak(columns, container){
		var listLength = container.find("a").size();
		var numInRow = Math.ceil(listLength / columns);
		for (var i=0; i<columns; i++){
			var listItems = container.find("a").slice(0, numInRow);
			var newList = $('<div class="col">').append(listItems);
			container.append(newList);
		}
	}
	columnsBreak(3, $("#global-nav .nav-group.featured-artists .sub-nav"));
	// Move Featured Artists link to the top of the nav
	$('#global-nav .nav-group.featured-artists .sub-nav [href="/featured-artists"]').prependTo($("#global-nav .nav-group.featured-artists .sub-nav .col:first-of-type"));

	// Page contents nav
	// Detect current position on page
	var waypoints = $('article section').waypoint({
		handler: function(direction) {
			var id = this.element.id,
				link = $('[href=#'+ id + ']');
			link.parent().parent().find('a').removeClass('current');
			link.addClass('current');
		}
	});
	// Make contents nav sticky
	var waypoints = $('nav.document-contents:not(.main)').waypoint({
		handler: function(direction) {
			var nav = $('nav.document-contents'),
				windowWidth = $(window).width();
			if ( direction === 'down' && windowWidth >= 1024 ) {
				nav.css( 'position', 'fixed' );
				nav.css( 'top', '20px' );
			} else if ( direction === 'up' && windowWidth >= 1024 ) {
				nav.css( 'position', '' );
				nav.css( 'top', '' );
			}
		},
		offset: 20
	});


})();
