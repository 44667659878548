// Scripts dealing with alerts & cookie notices
(function() {

	// Add cookie notice
	var hasSeenCookieNotice = document.cookie.replace(/(?:(?:^|.*;\s*)hasSeenCookieNotice\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	if (!hasSeenCookieNotice) {
		$('main').prepend('<div id="cookie-notice" class="cookie-notice"><p>We use cookies to provide you with a better service. Carry on browsing if you\'re happy with this, or find out how to manage <a href="/cookies">cookies</a>.</p> <span class="dismiss">Dismiss.</span></div>')
		var cookieNotice = $('#cookie-notice');
		var cookieNoticeDismiss = $('#cookie-notice .dismiss');
		cookieNoticeDismiss.on('click', function(event) {
			cookieNotice.fadeOut(200);
		});
	}
	document.cookie = 'hasSeenCookieNotice=true; expires=01 Jan 2100 00:00:00 UTC';

	$('.alert .close').on('click', function(e) {
		e.preventDefault();
		$(this).parent().slideUp();
	});
})();
