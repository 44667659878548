// Make links to external sites open in a new tab and add a class to them.

(function() {
	$('a').each(function() {
		var a = new RegExp('/' + window.location.host + '/');
		if(!a.test(this.href)) {
			var externalLink = $(this);
			externalLink.attr('target', '_blank');
			externalLink.addClass('external-link');
		}
	});
})();
